<template>
  <div class="page page-box page-padding">
    <!-- 页头 -->
    <header class="page-header">
      <el-form :inline="true" :model="listQuery" class="demo-form-inline">
        <el-form-item label="班级名称：">
          <template>
            <el-select v-model="listQuery.ClassName" filterable clearable remote reserve-keyword placeholder="请输入关键词" :remote-method="classMethod" :loading="optionLoading">
              <el-option v-for="item in classOptions" :key="item.Id" :label="item.Name" :value="item.Name">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="课程教练：">
          <template>
            <el-select v-model="listQuery.TeacherName" filterable clearable remote reserve-keyword placeholder="请输入关键词" :remote-method="teacherMethod" :loading="optionLoading">
              <el-option v-for="item in teacherOptions" :key="item.Id" :label="item.Name" :value="item.Name">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select class="input-box" v-model="listQuery.Status" clearable placeholder="请选择">
            <el-option v-for="item in CourseStatusList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button v-preventReClick @click="handleSearch" class="discoloration">查询</el-button>
          <el-button v-preventReClick @click="resetSearch" class="plain-button">重置</el-button>
          <el-button @click="addOrEidtClubSite()" icon="el-icon-plus">
            <!-- <div class="img-center"> <img src="@/assets/add.png" alt="添加" class="add-img"> <span>添加课程</span></div> -->
            添加课程
          </el-button>
        </el-form-item>
      </el-form>
    </header>
    <!-- 主体内容 -->
    <main class="page-main-main" ref='main'>
      <div class="scatter">
        <div></div>
        <el-button @click="batchCancel()">批量取消</el-button>
      </div>
      <!--表格渲染-->
      <el-table v-loading="loading" :data="tableList" border fit stripe :row-key='getRowKeys' @sort-change="changeTableSort" ref="multipleTable" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center" :reserve-selection="true" :selectable="
          (row) => {
            return row.Status === 1
          }
        ">
        </el-table-column>
        <el-table-column label="课程编码" prop="Code" align="center" width="90px" />
        <el-table-column label="班级名称" prop="ClassName" align="center" :show-overflow-tooltip="true" min-width="160" />
        <el-table-column label="课程主题" prop="Theme" align="center" :show-overflow-tooltip="true" />
        <el-table-column label="班级教练" align="center" :show-overflow-tooltip="true" min-width="160">
          <template slot-scope="{ row }">
            {{ Array.isArray(row.Teachers) && row.Teachers.map(item=> `${item.Name}(${item.TeacherType === 1 ? '主教练' : '助教'})`).join('/') }}
          </template>
        </el-table-column>
        <el-table-column label="上课时间" align="center" :show-overflow-tooltip="true" prop="StartTime" sortable min-width="104">
          <template slot-scope="{ row }">
            {{ (row.StartTime && moment(row.StartTime*1000).format('YYYY-MM-DD HH:mm'))+'-'+(row.EndTime && moment(row.EndTime*1000).format('HH:mm')) || '' }}
          </template>
        </el-table-column>
        <el-table-column label="训练场地" prop="SiteName" align="center" :show-overflow-tooltip="true" />
        <el-table-column label="状态" align="center" width="80px">
          <template slot-scope="{ row }">
            <span v-if="row.Status===1" class="pointStatus"><span class="span1"></span>{{ row.Status | courseStatus }}</span>
            <span v-if="row.Status===2" class="pointStatus"><span class="span2"></span>{{ row.Status | courseStatus }}</span>
            <span v-if="row.Status===3" class="pointStatus"><span class="span3"></span>{{ row.Status | courseStatus }}</span>
            <span v-if="row.Status===5" class="pointStatus"><span class="span5"></span>{{ row.Status | courseStatus }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" :show-overflow-tooltip="true" prop="CreateTime" sortable min-width="104">
          <template slot-scope="{ row }">
            {{ (row.CreateTime && moment(row.CreateTime*1000).format('YYYY-MM-DD HH:mm')) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding" width="140px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="readDetail(row)"> 详情 </el-button>
            <el-button type="text" v-if="row.Status === 1" @click="addOrEidtClubSite(row.Id)"> 编辑 </el-button>
            <el-button type="text" v-if="row.Status === 1" @click="openCancelVisible(row)"> 取消 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页部分 -->
      <footer>
        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.PageNo" :limit.sync="listQuery.PageSize" @pagination="getClubCourseList" />
      </footer>
    </main>
    <!-- 取消原因 -->
    <el-dialog title="取消原因" :visible.sync="cancelVisible" width="40%" :close-on-click-modal='false'>
      <el-form :model="cancelForm" :rules="rules" ref="cancelForm" label-width="100px">
        <el-form-item label="课程取消:" prop="Reason">
          <el-input v-model="cancelForm.Reason" :autosize="{ minRows: 3, maxRows: 5 }" type="textarea" placeholder="请输入课程取消原因" show-word-limit maxlength="200" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelVisible = false" class="plain-button">取 消</el-button>
        <el-button @click="cancelClubCourse()" class="discoloration">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog title="取消原因" :visible.sync="batchCancelVisible" width="40%" :close-on-click-modal='false'>
      <el-form :model="cancelForm" :rules="rules" ref="cancelForm" label-width="100px">
        <el-form-item label="课程取消:" prop="Reason">
          <el-input v-model="cancelForm.Reason" :autosize="{ minRows: 3, maxRows: 5 }" type="textarea" placeholder="请输入课程取消原因" show-word-limit maxlength="200" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchCancelVisible = false" class="plain-button">取 消</el-button>
        <el-button @click="batchCancelClubCourse()" class="discoloration">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getClubCourseList, cancelClubCourse, getAllClubClass, addCourseNotice, classNameFind, teacherNameFind } from "@/service/educational";
import { getAllClubTeacher } from "@/service/organizational";
import pagination from '@/components/pagination/pagination.vue';
import { CourseStatusList } from '@/config/data.js';
import { deepClone } from '@/utils/index';
import moment from 'moment';

const ListQuery = { // 查询数据
  PageNo: 1,
  PageSize: 50,
  ClassName: '',
  TeacherName: '',
  Status: undefined,
};

export default {
  name: "Curriculum",
  components: {
    pagination
  },
  data() {
    const validateReason = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入取消课程原因'));
      }
      callback();
    };

    return {
      moment,
      CourseStatusList,
      listQuery: deepClone(ListQuery),
      tableList: [], // 表格数据
      loading: false, // 加载状态
      total: 0,  // 表格总条数
      readVisible: false, // 详情框初始状态
      readCurriculum: {}, // 详情数据
      cancelVisible: false, // 取消原因框初始状态
      cancelForm: {
        Reason: '', // 初始化
      }, // 取消原因数据
      rules: {
        Reason: [{ required: true, validator: validateReason, trigger: 'blur' }],
      },
      sortingType: undefined,
      sortProperty: undefined,
      optionLoading: false,
      classOptions: [],
      teacherOptions: [],
      classList: [],
      teacherList: [],
      readDetailDate: {},
      multipleSelection: [],  // 多选数据
      batchCancelVisible: false,
    };
  },
  activated() {
    this.getClubCourseList();
    // this.getAllClubClass();
    // this.getAllClubTeacher();
    this.classNameFind();
    this.teacherNameFind();
  },
  methods: {
    // 输入框联想
    classMethod(query) {
      if (query && query.trim() !== '') {
        this.optionLoading = true;
        setTimeout(() => {
          this.optionLoading = false;
          this.classOptions = this.classList.filter(item => {
            return item.Name.toLowerCase()
              .indexOf(query.trim().toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.classOptions = this.classList;
      }
    },

    teacherMethod(query) {
      if (query && query.trim() !== '') {
        this.optionLoading = true;
        setTimeout(() => {
          this.optionLoading = false;
          this.teacherOptions = this.teacherList.filter(item => {
            return item.Name.toLowerCase()
              .indexOf(query.trim().toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.teacherOptions = this.teacherList;
      }
    },

    /**
     * 获取所有班级列表
     */
    async classNameFind() {
      let res = await classNameFind();
      this.classList = res.map((item, index) => {
        let obj = {};
        obj.Name = item;
        obj.Id = index + 1
        return obj;
      });
      this.classOptions = this.classList;
    },

    /**
     * 获取教练列表数据
     */
    async teacherNameFind() {
      let res = await teacherNameFind();
      this.teacherList = res.map((item, index) => {
        let obj = {};
        obj.Name = item;
        obj.Id = index + 1
        return obj;
      });
      this.teacherOptions = this.teacherList;
    },

    /**
     * 获取课程列表数据
     */
    async getClubCourseList(data) {
      if (data) {
        this.listQuery.PageNo = data.page;
        this.listQuery.PageSize = data.limit;
      }
      this.loading = true;
      let res = await getClubCourseList({
        ...this.listQuery,
        SortProperty: this.sortProperty || undefined,
        Sequencer: this.sortingType || undefined
      });
      this.loading = false;
      this.tableList = res.DataList;
      this.total = res.Total;
    },
    /**
     * 查询
     */
    handleSearch() {
      this.listQuery.PageNo = 1;
      this.listQuery.ClassName = this.listQuery.ClassName && this.listQuery.ClassName.trim();
      this.listQuery.TeacherName = this.listQuery.TeacherName && this.listQuery.TeacherName.trim();
      this.listQuery.Status = this.listQuery.Status || undefined;
      this.classOptions = this.classList;
      this.teacherOptions = this.teacherList;
      this.getClubCourseList();
    },

    /**
     * 重置
     */

    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.classOptions = this.classList;
      this.teacherOptions = this.teacherList;
      this.getClubCourseList();
    },

    /**
     * 查看
     */
    readDetail(row) {
      this.$router.push({
        path: '/educational/readDetail',
        query: {
          id: row.Id
        }
      })
    },

    /**
     * 新增或者编辑
     */
    addOrEidtClubSite(Id) {
      this.$router.push({
        path: '/educational/createCurriculum',
        query: {
          Id: Id || 0
        }
      });
    },

    /**
     * 取消
     */
    async openCancelVisible(row) {
      this.cancelVisible = true;
      this.cancelForm.Reason = '';  // 清空
      this.cancelForm.Id = [row.Id];  // 存值
      this.readDetailDate = { ...row };
    },

    async cancelClubCourse(row) {
      this.$refs.cancelForm.validate(async (valid) => {
        if (!valid) return;
        try {
          let res = await this.$confirm('你是否确定取消该课程？', '警告', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确 定',
            cancelButtonText: '取 消',
            type: 'warning',
            center: true,
          })
          if (res !== 'confirm') return;
          await cancelClubCourse({ Ids: this.cancelForm.Id, Reason: this.cancelForm.Reason });
          this.$message.success('取消课程成功');
          this.cancelVisible = false;
        } catch (err) {
          // this.getClubCourseList();
        };

        try {
          let req = await this.$confirm('课程已取消，是否立即通知家长？。', '提示', {
            distinguishCancelAndClose: true,
            confirmButtonText: '通知家长',
            cancelButtonText: '取 消',
            type: 'warning',
            center: true,
          })
          if (req !== 'confirm') return;
          await addCourseNotice({
            ClubClassId: this.readDetailDate.ClassId,
            ClubCourseId: this.readDetailDate.Id,
            Type: 3
          });
          this.$message.success('发送通知成功');
          this.getClubCourseList();
        } catch (err) {
          this.getClubCourseList();
        }
        this.$refs.multipleTable.clearSelection();
      })
    },

    getRowKeys(row) {
      return row.Id;
    },

    /**
     * 主体-table表格多选框操作
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    /**
     * 批量取消课程
     */
    batchCancel() {
      if (Array.isArray(this.multipleSelection) && this.multipleSelection.length === 0) {
        this.$message.error('请勾选至少一条数据');
        return;
      }
      this.batchCancelVisible = true;
      this.cancelForm.Reason = '';  // 清空
    },
    async batchCancelClubCourse() {
      try {
        let res = await this.$confirm('是否进行批量取消课程操作？', '批量取消课程', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确 定',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        });
        if (res !== 'confirm') return;
        await cancelClubCourse({
          Ids: this.multipleSelection.map(item => item.Id),
          Reason: this.cancelForm.Reason
        });
        this.$message({
          message: '取消课程成功',
          type: 'success'
        });
        this.batchCancelVisible = false;
        let req = await this.$confirm('课程已取消，是否立即通知家长最近一节课程信息？。', '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '通知家长',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        })
        if (req !== 'confirm') return;
        let arr = this.multipleSelection.sort((a, b) => {
          return a.StartTime - b.StartTime
        })
        await addCourseNotice({
          ClubClassId: arr[0].ClassId,
          ClubCourseId: arr[0].Id,
          Type: 3
        });
        this.$message.success('发送通知成功');
        this.$refs.multipleTable.clearSelection();
        this.getClubCourseList();
      } catch (err) { };
    },

    //选择指定列进行排序
    changeTableSort(column) {
      //获取字段名称和排序类型
      let sortingType = column.order;
      this.sortProperty = column.prop === 'StartTime' ? 'start_time' : 'create_time';
      //按照降序排序
      if (sortingType == "descending") {
        this.sortingType = 'desc';
      } else if (sortingType == "ascending") {
        this.sortingType = 'asc';
      }
      //按照升序排序
      else {
        this.sortProperty = undefined;
        this.sortingType = undefined;
      }
      // 判断是降序升序还是按原来排序，请求接口，使用接口排序
      this.getClubCourseList();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-table__body tr,
.el-table__body td {
  height: 54px !important;
}
.scatter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.pointStatus {
  display: flex;
  align-items: center;
  span {
    // display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .span1 {
    background: #ff8900;
  }
  .span2 {
    background: #00b446;
  }
  .span3 {
    background: #a6b4bf;
  }
  .span5 {
    background: #fe3a30;
  }
}
</style>
